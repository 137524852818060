// Variables
@import "../../assets/css/vars";

h2 {
  text-align: center;
}

#AddButton {
  text-align: start;
}
