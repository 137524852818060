// Variables
@import "../../assets/css/vars";

.gallery-image {
    width: 100px;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    max-height: 65px;
    object-position: center;
    object-fit: cover;
}

.play-video {
    width: 100px;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.60);
    height: 65px;
    object-position: center;
    color: $white !important;
    background-color: $dark-blue-two;
    font-size: 30px;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}