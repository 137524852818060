// Variables
@import "../../assets/css/vars";

header {
  .top-bar {
    padding: 10px 0;
    background-color: $white;

    img {
      max-height: 100px;
      max-width: 240px;
    }

    .p-button {
      background-color: $blue !important;
      border-color: $blue !important;
    }
  }

  .banner {
    background-color: $dark-blue-two;
    padding: 40px;
    font: $med-size-sbold;
    text-align: center;
    color: $white;

    h3 {
      margin-bottom: 0 !important;
    }
  }
}
